import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContactPage from "../../pages/ContactPage/ContactPage";
import Homepage from "../../pages/Homepage/Homepage";
import InfoPage from "../../pages/InfoPage/InfoPage";
import Styles from "./Landing.module.css";

const Landing = () => {
	const { isHeaderOpen, isFormOpen } = useSelector(
		(state) => state.headerReducer
	);

	const [currentLanding, setCurrentLanding] = useState("home");

	useEffect(() => {
		setCurrentLanding("null");

		if (isHeaderOpen === true && isFormOpen === true) {
			setCurrentLanding("contact");
		} else if (isHeaderOpen === true && isFormOpen === false) {
			setCurrentLanding("info");
		} else {
			setCurrentLanding("home");
		}
	}, [isHeaderOpen, isFormOpen]);

	return (
		<div
			className={`${Styles.landingWrapper}`}
			data-landing={currentLanding}
		>
			<div className={Styles.homePageWrapper}>
				<div className={`${Styles.homePage}`}>
					<Homepage />
				</div>
			</div>
			<div className={Styles.infoPageWrapper}>
				<div className={`${Styles.infoPage}`}>
					<InfoPage />
				</div>
			</div>
			<div className={Styles.contactPageWrapper}>
				<div className={`${Styles.contactPage}`}>
					<ContactPage />
				</div>
			</div>
		</div>
	);
};

export default Landing;
