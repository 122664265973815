import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
// import Homepage from "./pages/Homepage/Homepage";
// import InfoPage from "./pages/InfoPage/InfoPage";
// import ContactPage from "./pages/ContactPage/ContactPage";

const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/" element={<LandingPage />} />
			{/* <Route path="/" element={<Homepage />} /> */}
			{/* <Route path="/info" element={<InfoPage />} /> */}
			{/* <Route path="/contact" element={<ContactPage />} /> */}
		</Routes>
	);
};

export default AppRoutes;
