import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import InfoComponent from "../../components/Info/InfoComponent";

const InfoPage = () => {
	return (
		<>
			<Header />
			<InfoComponent />
			<Footer />
		</>
	);
};

export default InfoPage;
