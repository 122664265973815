import React from "react";
import Styles from "./Footer.module.css";

const Footer = () => {
	return (
		<>
			<div className={Styles.footerWrapper}>
				<div className={Styles.footerContainer}>
					<div className={Styles.footerText}>
						© 2023 - Fluide Diagnostics Private Limited
					</div>
					{/* <div
						className={`${Styles.footerText} ${Styles.footerText2}`}
					>
						All rights reserved
					</div> */}
					<div className={Styles.footerLine}></div>
				</div>
			</div>
		</>
	);
};

export default Footer;
