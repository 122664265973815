import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "../CustomHooks/ApiCalls";

const initialState = {
	loading: false,
	formData: {},
	error: "",
};

export const submitForm = createAsyncThunk(
	"contactForm/submitForm",
	async (data) => {
		try {
			let url = `https://jb5rz2hzl5.execute-api.ap-south-1.amazonaws.com/v1/sendDetails`;
			let headers = {
				"Content-Type": "application/json",
			};
			let body = {
				name: data?.name,
				phone_number: data?.phone,
				location: data?.currentCity,
				interest: data?.currentType,
				message: data?.message,
			};

			const response = await postApi(url, body, headers);

			return response.data;
		} catch (error) {
			throw error;
		}
	}
);

export const FormSlice = createSlice({
	name: "contactForm",
	initialState,
	extraReducers: (builder) => {
		builder.addCase(submitForm.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(submitForm.fulfilled, (state, action) => {
			state.loading = false;
			state.formData = action.payload;
			state.error = "";
		});
		builder.addCase(submitForm.rejected, (state, action) => {
			state.loading = false;
			state.formData = {};
			state.error = action.error.message;
		});
	},
});

export default FormSlice.reducer;
