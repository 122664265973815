import { useDispatch } from "react-redux";
import { openForm } from "../../features/Header/HeaderSlice";
import Styles from "./InfoComponent.module.css";
// import { useNavigate } from "react-router-dom";

const InfoComponent = () => {
	// const navigate = useNavigate();
	const dispatch = useDispatch();

	const openContactForm = () => {
		// navigate("/contact");
		dispatch(openForm());
	};

	return (
		<>
			<div className={Styles.mainContainer}>
				<div className={Styles.infoComponent}>
					<div
						className={`${Styles.infoHeading1} ${Styles.infoHeading}`}
					>
						Are you an experienced healthcare professional?
					</div>
					<div
						className={`${Styles.infoHeading2} ${Styles.infoHeading}`}
					>
						Do you want to solve the healthcare needs of today?
					</div>
					<div
						className={`${Styles.infoHeading3} ${Styles.infoHeading}`}
					>
						Phlebotomists
						<div className={Styles.inforHeadingDivider}>|</div>
						Runners
						<div className={Styles.inforHeadingDivider}>|</div>
						Delivery Executives
					</div>
				</div>
				<div className={Styles.contactButton} onClick={openContactForm}>
					<div className={Styles.contactText1}>Contact Us</div>
					<div className={Styles.contactText2}>Join Our Team</div>
				</div>
			</div>
		</>
	);
};

export default InfoComponent;
