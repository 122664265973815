import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Home from "../../components/Home/Home";

const Homepage = () => {
	return (
		<>
			<Header />
			<Home />
			<Footer />
		</>
	);
};

export default Homepage;
