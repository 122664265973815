import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactForm from "../../components/ContactForm/ContactForm";

const ContactPage = () => {
	return (
		<>
			<Header />
			<ContactForm />
			<Footer />
		</>
	);
};

export default ContactPage;
