import React from "react";

import BrandIcon from "./Assets/BrandIcon.svg";
import HeaderIconClosed from "./Assets/HeaderIconClosed.svg";
import HeaderIconOpen from "./Assets/HeaderIconOpen.svg";
import Styles from "./Header.module.css";

import { useDispatch, useSelector } from "react-redux";
import { closeHeader, openHeader } from "../../features/Header/HeaderSlice";
// import { useNavigate } from "react-router-dom";

const Header = () => {
	const { isHeaderOpen } = useSelector((state) => state.headerReducer);
	// const navigate = useNavigate();
	const dispatch = useDispatch();

	const openMenu = () => {
		// navigate("/info");
		dispatch(openHeader());
	};

	const closeMenu = () => {
		// navigate("/");
		dispatch(closeHeader());
	};

	const openForm = () => {
		window.open(
			"https://docs.google.com/forms/d/e/1FAIpQLSeKibPzwvqrdWkZpDvsmVImUdjvGIC1gJgSFihCPGYhsoAeSA/viewform?pli=1",
			"_blank"
		);
	};

	const HeaderIconContainer = () => {
		const icon = isHeaderOpen === true ? HeaderIconOpen : HeaderIconClosed;
		const toggle = isHeaderOpen === true ? closeMenu : openMenu;
		const infoText = isHeaderOpen === true ? "CLOSE" : "MORE INFO";

		return (
			<>
				<div
					className={`${Styles.headerIconContainer} ${
						isHeaderOpen === true && Styles.headerIconOpen
					}`}
				>
					<>
						<img
							src={icon}
							alt=""
							className={Styles.headerIcon}
							onClick={toggle}
						/>
						<div className={Styles.headerInfo}>{infoText}</div>
					</>
				</div>
			</>
		);
	};

	return (
		<>
			<div className={Styles.headerWrapper}>
				<div className={Styles.header}>
					<div className={Styles.headerIconWrapper}>
						<div
							className={`${Styles.iconLine} ${Styles.iconLine1}`}
						></div>
						<HeaderIconContainer />
						<div
							className={`${Styles.iconLine} ${Styles.iconLine2}`}
						></div>
					</div>
					<div className={Styles.bookCTA} onClick={openForm}>
						BOOK A PHLEBO
					</div>
				</div>
				<div className={Styles.brandIconContainer}>
					<img src={BrandIcon} alt="" className={Styles.brandIcon} />
					<div className={Styles.headerLine}></div>
				</div>
			</div>
			<div className={Styles.headerPlaceholder}></div>
		</>
	);
};

export default Header;
