import React, { useEffect, useState } from "react";
import Styles from "./ContactForm.module.css";

import DropDownIcon from "./Assets/DropDownIcon.svg";
import IndiaFlag from "./Assets/IndiaFlag.svg";
import PeopleImage from "./Assets/People.png";
import TickMark from "./Assets/tickMark.svg";
import { useDispatch } from "react-redux";
import { submitForm } from "../../features/ContactForm/FormSlice";

const ContactForm = () => {
	const [submit, setSubmit] = useState(false);

	return (
		<>
			<div className={Styles.formWrapper}>
				<div className={Styles.formImageContainer}>
					<img
						src={PeopleImage}
						alt=""
						className={Styles.formImage}
					/>
				</div>
				<div className={Styles.formContainer}>
					<div className={Styles.formImagePlaceholder}></div>
					<div className={Styles.form}>
						{submit === true ? (
							<ThankYou />
						) : (
							<>
								<FormBody
									handleSubmit={() => setSubmit(true)}
								/>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

const ThankYou = () => {
	return (
		<>
			<div className={Styles.formHeader}>
				<div className={Styles.formHeaderText1}>
					Thank You for enrolling at PhleboPro
				</div>
			</div>
			<div className={Styles.thankyouBody}>
				PhleboPRO counsellor will call you on your number shortly
			</div>
		</>
	);
};

const FormBody = ({ handleSubmit }) => {
	const dispatch = useDispatch();

	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");

	const [currentType, setCurrentType] = useState(0);

	const [currentCity, setCurrentCity] = useState(0);
	const [selectDropdown, setSelectDropdown] = useState(null);

	const [message, setMessage] = useState("");

	const [permissionChecked, setPermissionChecked] = useState(false);

	const [submitButtonState, setSubmitButtonState] = useState("disabled");

	const TypeItemList = [
		"Interested in enrolling for?",
		"Phlebotomy Service",
		"Runner Service",
		"Sample Logistics",
		"All of the above",
	];
	const CityItemList = [
		"Choose your work city",
		"Mumbai",
		"Navi Mumbai",
		"Thane",
		"All of the above",
	];

	const toggleDropdown = (e, value) => {
		setSelectDropdown((prev) => {
			if (value === prev) {
				return null;
			}
			if (e.target) {
				e.target.scrollIntoView({
					behavior: "smooth",
				});
			}
			return value;
		});
	};

	const chooseType = (val) => {
		setCurrentType(val);
		toggleDropdown("", null);
	};

	const chooseCity = (val) => {
		setCurrentCity(val);
		toggleDropdown("", null);
	};

	const handlePermission = () => {
		setPermissionChecked((prev) => !prev);
	};

	useEffect(() => {
		setSubmitButtonState("active");

		if (name.length < 1) {
			setSubmitButtonState("disabled");
		}
		if (phone.length !== 10) {
			setSubmitButtonState("disabled");
		}
		if (currentType === 0) {
			setSubmitButtonState("disabled");
		}
		if (currentCity === 0) {
			setSubmitButtonState("disabled");
		}
		// if (message.length < 1) {
		// 	setSubmitButtonState("disabled");
		// }
		if (permissionChecked === false) {
			setSubmitButtonState("disabled");
		}
	}, [name, phone, currentType, currentCity, message, permissionChecked]);

	const submitDetails = () => {
		if (submitButtonState === "sent" || submitButtonState === "sending") {
			return;
		}
		setSubmitButtonState("sending");

		const response = dispatch(
			submitForm({
				name,
				phone,
				currentType: TypeItemList[currentType],
				currentCity: CityItemList[currentCity],
				message,
				permissionChecked,
			})
		);
		console.log("response", response, {
			name,
			phone,
			currentType: TypeItemList[currentType],
			currentCity: CityItemList[currentCity],
			message,
			permissionChecked,
		});
		setTimeout(() => {
			setSubmitButtonState("sent");
		}, 3000);

		setTimeout(() => {
			handleSubmit();
		}, 5000);
	};

	return (
		<>
			<div className={Styles.formHeader}>
				<div className={Styles.formHeaderText1}>
					Allow PhleboPRO to Contact You
				</div>
				<div className={Styles.formHeaderText2}>
					Fill out the form below{" "}
					<span className={Styles.formHeaderTextDesktop}>
						and we will connect with you
					</span>
				</div>
			</div>
			<div className={Styles.formBody}>
				<div className={Styles.detailsContainer}>
					<label htmlFor="name" className={Styles.nameContainer}>
						<span className={Styles.nameLabel}>
							Full Name (as in Aadhaar card)
						</span>
						<input
							tabIndex="0"
							type="text"
							id="name"
							className={Styles.nameInput}
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</label>
					<label htmlFor="phone" className={Styles.phoneContainer}>
						<div className={Styles.areaCode}>
							<img src={IndiaFlag} alt="" />
							+91
						</div>
						<span className={Styles.phoneLabel}>
							Your Mobile Number
						</span>
						<input
							tabIndex="0"
							type="number"
							id="phone"
							className={Styles.phoneInput}
							value={phone}
							onChange={(e) => {
								if (e.target.value.length <= 10) {
									setPhone(e.target.value);
								}
							}}
						/>
					</label>
				</div>
				<div className={Styles.detailsContainer}>
					<div
						tabIndex="0"
						className={Styles.typeContainer}
						onBlur={(e) => toggleDropdown(e, null)}
						onFocus={(e) => toggleDropdown(e, "type")}
						// onClick={(e) => toggleDropdown(e, "type")}
					>
						<div className={Styles.typeInput}>
							<span>{TypeItemList[currentType]}</span>
							<img
								src={DropDownIcon}
								alt=""
								className={`${Styles.dropDownIcon} ${
									selectDropdown === "type" &&
									Styles.dropDownIconActive
								}`}
							/>
						</div>
						<DropDown 
							show={selectDropdown === "type"}
							choose={(val) => chooseType(val)}
							currentActive={currentType}
							itemList={TypeItemList}
						/>
					</div>
					<div
						tabIndex="0"
						className={Styles.cityContainer}
						onBlur={(e) => toggleDropdown(e, null)}
						onFocus={(e) => toggleDropdown(e, "city")}
						// onClick={(e) => toggleDropdown(e, "city")}
					>
						<div className={Styles.cityInput}>
							<span>{CityItemList[currentCity]}</span>
							<img
								src={DropDownIcon}
								alt=""
								className={`${Styles.dropDownIcon} ${
									selectDropdown === "city" &&
									Styles.dropDownIconActive
								}`}
							/>
						</div>
						<DropDown
							show={selectDropdown === "city"}
							choose={(val) => chooseCity(val)}
							currentActive={currentCity}
							itemList={CityItemList}
						/>
					</div>
				</div>
				<label htmlFor="message" className={Styles.messageContainer}>
					<span className={Styles.messageLabel}>
						Enter your message here
					</span>
					<textarea
						tabIndex="0"
						type="text"
						id="message"
						className={Styles.messageInput}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
				</label>
			</div>
			<div className={Styles.formButtonContainer}>
				<label
					htmlFor="permission"
					className={Styles.permissionContainer}
				>
					<input
						type="checkbox"
						name="permission"
						id="permission"
						className={Styles.permissionInput}
						checked={permissionChecked}
						onChange={handlePermission}
					/>
					<div className={Styles.permissionInputBox}>
						{permissionChecked ? (
							<>
								<img src={TickMark} alt="" />
							</>
						) : (
							<></>
						)}
					</div>
					<span className={Styles.permissionLabel}>
						I authorize the PhleboPRO team to contact me & send me
						communication via SMS and/ or WhatsApp
					</span>
				</label>
				<SubmitButton
					submitButtonState={submitButtonState}
					submitDetails={submitDetails}
				/>
			</div>
		</>
	);
};

const DropDown = ({ show, choose, currentActive, itemList }) => {
	const isActive = (index) => {
		return currentActive === index;
	};
	return (
		<>
			<div
				className={`${Styles.dropDownContainer} ${
					show && Styles.dropDownContainerActive
				}`}
			>
				<div className={Styles.expandable}>
					{itemList.map((el, ind) => (
						<div
							className={`${Styles.dropDownItem} ${
								isActive(ind) === true &&
								Styles.dropDownItemActive
							}`}
							onClick={() => {
								choose(ind);
							}}
							key={ind}
						>
							{el}
						</div>
					))}
				</div>
			</div>
		</>
	);
};

const SubmitButton = ({ submitButtonState, submitDetails }) => {
	return (
		<>
			<button
				className={`${Styles.formButton}`}
				disabled={submitButtonState === "disabled"}
				onClick={submitDetails}
			>
				<div
					className={Styles.buttonContainer}
					data-state={submitButtonState}
				>
					<div className={`${Styles.sentButton}`}>
						<span>Message Sent!</span>
					</div>
					<div className={`${Styles.sendingButton}`}>
						Sending Message <LoadingDots />
					</div>
					<div className={`${Styles.sendButton}`}>
						Send Your Message
					</div>
					<div className={`${Styles.disabledButton}`}>
						SUBMIT MY DETAILS
					</div>
				</div>
			</button>
		</>
	);
};

const LoadingDots = () => {
	return (
		<div className={Styles.dotContainer}>
			<div className={Styles.dot}></div>
			<div className={Styles.dot}></div>
			<div className={Styles.dot}></div>
		</div>
	);
};

export default ContactForm;
