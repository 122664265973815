import React from "react";
import Landing from "../../components/Landing/Landing";

const LandingPage = () => {
	return (
		<>
			<Landing />
		</>
	);
};

export default LandingPage;
