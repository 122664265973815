import React from "react";
import Styles from "./Home.module.css";

const Home = () => {
	const openForm = () => {
		window.open(
			"https://docs.google.com/forms/d/e/1FAIpQLSeKibPzwvqrdWkZpDvsmVImUdjvGIC1gJgSFihCPGYhsoAeSA/viewform?pli=1",
			"_blank"
		);
	};

	return (
		<>
			<div className={Styles.bookCTAWrapper}>
				<div className={Styles.bookCTA} onClick={openForm}>
					BOOK A PHLEBO
				</div>
			</div>
			<div className={Styles.homeWrapper}>
				<div className={Styles.bodyHeading}>
					<div className={Styles.bodyHeadingLine}></div>
					<div className={Styles.bodyHeadingText}>
						WELCOME TO PhleboPRO
					</div>
				</div>
				<div className={Styles.bodyText}>
					We are currently working on a new super awesome service
					offering.
				</div>
				<div className={Styles.bodyFooter}>
					<div className={Styles.bodyFooterText}>PhleboPRO</div>
					<div className={Styles.bodyFooterDivider}></div>
					<div className={Styles.bodyFooterText}>
						Creating opportunities for Phlebotomist
					</div>
					<div className={Styles.bodyLine}></div>
				</div>
			</div>
		</>
	);
};

export default Home;
