import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isHeaderOpen: false,
	isFormOpen: false,
};

export const HeaderSlice = createSlice({
	name: "header",
	initialState,
	reducers: {
		openHeader: (state, action) => {
			state.isHeaderOpen = true;
		},
		closeHeader: (state, action) => {
			state.isHeaderOpen = false;
			state.isFormOpen = false;
		},
		openForm: (state, action) => {
			state.isFormOpen = true;
		},
		closeForm: (state, action) => {
			state.isFormOpen = false;
		},
	},
});

export const { openHeader, closeHeader, openForm, closeForm } =
	HeaderSlice.actions;

export default HeaderSlice.reducer;
